import { useRuntimeConfig } from '#imports'
import * as Sentry from '@sentry/nuxt'

Sentry.init({
  environment: useRuntimeConfig().public.env,
  // If set up, you can use your runtime config here
  // dsn: useRuntimeConfig().public.sentry.dsn,
  dsn: 'https://7d165d816d4a608262faaa0352731ce1@sg-sentry.changzhi.top/24',
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0,
})
